import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const MenuDevider = () => {
  return <div className="MenuDevider" />;
};

export default MenuDevider;
