const MenuOn = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 36C7.075 36 6.71875 35.8554 6.43125 35.5663C6.14375 35.2771 6 34.9187 6 34.4913C6 34.0638 6.14375 33.7083 6.43125 33.425C6.71875 33.1417 7.075 33 7.5 33H40.5C40.925 33 41.2813 33.1446 41.5688 33.4337C41.8563 33.7229 42 34.0813 42 34.5087C42 34.9362 41.8563 35.2917 41.5688 35.575C41.2813 35.8583 40.925 36 40.5 36H7.5ZM7.5 25.5C7.075 25.5 6.71875 25.3554 6.43125 25.0663C6.14375 24.7771 6 24.4187 6 23.9913C6 23.5638 6.14375 23.2083 6.43125 22.925C6.71875 22.6417 7.075 22.5 7.5 22.5H40.5C40.925 22.5 41.2813 22.6446 41.5688 22.9337C41.8563 23.2229 42 23.5813 42 24.0087C42 24.4362 41.8563 24.7917 41.5688 25.075C41.2813 25.3583 40.925 25.5 40.5 25.5H7.5ZM7.5 15C7.075 15 6.71875 14.8554 6.43125 14.5663C6.14375 14.2771 6 13.9187 6 13.4913C6 13.0638 6.14375 12.7083 6.43125 12.425C6.71875 12.1417 7.075 12 7.5 12H40.5C40.925 12 41.2813 12.1446 41.5688 12.4337C41.8563 12.7229 42 13.0813 42 13.5087C42 13.9362 41.8563 14.2917 41.5688 14.575C41.2813 14.8583 40.925 15 40.5 15H7.5Z"
        fill="#464646"
      />
    </svg>
  );
};

export default MenuOn;
