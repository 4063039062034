import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const Contact = () => {
  function getFormData(form) {
    let elements = form.elements;
    let honeypot;

    let fields = Object.keys(elements)
      .filter(function (k) {
        if (elements[k].name === "honeypot") {
          honeypot = elements[k].value;
          return false;
        }
        return true;
      })
      .map(function (k) {
        if (elements[k].name !== undefined) {
          return elements[k].name;
        } else if (elements[k].length > 0) {
          return elements[k].item(0).name;
        }
        return null; // 값을 반환하도록 추가
      })
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos && item;
      });

    let formData = {};
    fields.forEach(function (name) {
      let element = elements[name];

      // singular form elements just have one value
      formData[name] = element.value;

      // when our element has multiple items, get their values
      if (element.length) {
        let data = [];
        for (let i = 0; i < element.length; i++) {
          let item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(", ");
      }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
    formData.formGoogleSendEmail = form.dataset.email || ""; // no email by default

    return { data: formData, honeypot: honeypot };
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let form = event.target;
    let formData = getFormData(form);
    let data = formData.data;
    if (formData.honeypot) {
      return false;
    }
    disableAllButtons(form);
    let url = form.action;
    let xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        form.reset();
        // let formElements = form.querySelector(".Contact_FormContents");
        // if (formElements) {
        //   formElements.style.display = "none"; // hide form
        // }
        let thankYouMessage = form.querySelector(".Contact_Success_CT");
        if (thankYouMessage) {
          thankYouMessage.style.display = "block";
        }
      }
    };
    let encoded = Object.keys(data)
      .map(function (k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
      })
      .join("&");
    xhr.send(encoded);
  };

  function disableAllButtons(form) {
    let buttons = form.querySelectorAll("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }
  return (
    <div className="News_CT">
      <div className="About_Header_Image">
        <img
          src={require("../Assets/Images/GDPHomeBG2.png")}
          alt="AboutBG"
          width="100%"
          height="auto"
        />
      </div>

      <div className="News_Paragraph_CT">
        <div className="News_Subject">
          <div className="Copy_2_600_A">Contact</div>
        </div>
        <div className="Contact_Contents">
          <div className="Contact_Texts">
            <div className="Contact_Email">
              <div className="Copy_4_600_A">Email</div>
              <div className="Copy_4_300_B">help@certifie.io</div>
            </div>
            <div className="Contact_Adress">
              <div className="Copy_4_600_A">Address</div>
              <div className="Copy_4_300_B">
                서울특별시 금천구 가산디지털1로 120 서울디지털산업단지
                G밸리창업큐브 603 (08590)
              </div>
            </div>
          </div>

          <form
            action="https://script.google.com/macros/s/AKfycbxX3r6gzhQhUVpIpw0jfglvJ7Ehf0zHBQ2Y8ehTuFt_A80VWH8R0zOvKxO8wBVkxcZG/exec"
            method="POST"
            data-email="andy@certifie.io"
            className="Contact_gform_CT"
            onSubmit={handleFormSubmit}
          >
            <div className="Contact_FormContents">
              <fieldset className="Contact_BG">
                <div className="Contact_Subject_CT_First">
                  <label className="Contact_Label" htmlFor="userName">
                    Name
                  </label>
                  <input
                    className="Contact_Input"
                    type="text"
                    id="userName"
                    name="name"
                    placeholder="이름을 입력해주세요."
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="Contact_Subject_CT">
                  <label className="Contact_Label" htmlFor="userEmail">
                    Email
                  </label>
                  <input
                    className="Contact_Input"
                    type="email"
                    id="userEmail"
                    name="email"
                    placeholder="연락 받을 이메일 주소를 입력해주세요."
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="Contact_Subject_CT">
                  <label className="Contact_Label" htmlFor="userMessage">
                    Message
                  </label>
                  <textarea
                    className="Contact_Message"
                    name="message"
                    id="userMessage"
                    required
                  ></textarea>
                </div>
                <div className="Contact_Success_CT">
                  <div className="Contact_Success_Text">
                    연락주셔서 감사합니다. 빠른 시일 내에 답변 드리겠습니다.
                  </div>
                </div>
              </fieldset>
              <button className="Contact_Button" type="submit">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
