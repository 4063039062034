import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import ArrowIcon from "../Components/ArrowIcon";
import React, { useEffect, useRef } from "react";

const Product = () => {
  const headerImageRef = useRef(null);

  useEffect(() => {
    const setHeaderImageWidth = () => {
      const screenWidth = window.innerWidth;
      const headerImage = headerImageRef.current;

      if (screenWidth >= 1024 && screenWidth <= 1240) {
        headerImage.style.width = "100%";
      } else {
        headerImage.style.width = "150%";
      }
    };

    setHeaderImageWidth(); // 페이지 로드 시 초기 너비 설정

    window.addEventListener("resize", setHeaderImageWidth);
    return () => {
      window.removeEventListener("resize", setHeaderImageWidth);
    };
  }, []);
  return (
    <div className="Product_CT">
      <div className="Product_Banner_CT">
        <div className="Product_Contents">
          <div className="Product_Texts">
            <div
              className="Copy_1_200_A"
              style={{ color: "rgb(170, 170, 170)" }}
            >
              직장은 잠깐이지만
            </div>
            <div className="Copy_1_200_A">경력은 영원하니까</div>
            <div className="Copy_1_200_A" style={{ fontWeight: 700 }}>
              써티파이
            </div>
            <div className="Product_BodyText">
              <div className="Copy_4_400_A">
                증명서 발급 부터 전송까지 한번에!
              </div>
            </div>
            <button
              className="Product_TextButton_CT"
              onClick={() => {
                window.location.href = "https://certifie.io";
              }}
            >
              <div className="Product_TextButton_Text">
                <div className="Copy_4_300_A">사이트 바로가기</div>
              </div>
              <div className="Product_TextButton_Icon">
                <ArrowIcon />
              </div>
            </button>

            <div className="Product_Buttons_CT">
              <div className="Product_Apple">
                <button
                  className="Product_Button_BG"
                  onClick={() => {
                    window.location.href =
                      "https://apps.apple.com/kr/app/%EC%8D%A8%ED%8B%B0%ED%8C%8C%EC%9D%B4/id6447591094?platform=iphone";
                  }}
                >
                  <img
                    src={require("../Assets/Images/appleLogo.png")}
                    alt="appleLogo"
                    width="100%"
                    height="autoHeight"
                    style={{
                      maxWidth: "32px",
                      display: "flex",
                      marginRight: "3%",
                    }}
                  />
                  <div className="Copy_6_500_A">App Store</div>
                </button>
              </div>
              <div className="Product_Google">
                <button
                  className="Product_Button_BG"
                  onClick={() => {
                    window.location.href =
                      "https://play.google.com/store/apps/details?id=com.gdpstudio.certifie&hl=ko";
                  }}
                >
                  <img
                    src={require("../Assets/Images/GooglePlayLogo.png")}
                    alt="GooglePlayLogo"
                    width="100%"
                    height="autoHeight"
                    style={{
                      maxWidth: "32px",
                      display: "flex",
                      marginRight: "3%",
                    }}
                  />
                  <div className="Copy_6_500_A">Google Play</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Product_Illust">
          <img
            ref={headerImageRef}
            src={require("../Assets/Images/headerBGIllust.png")}
            alt="headerBGIllust"
            width="100%"
            height="autoHeight"
            style={{ maxWidth: "600px" }}
            id="headerImage"
          />
        </div>
      </div>
    </div>
  );
};

export default Product;
