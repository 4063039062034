import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const Partner = () => {
  return (
    <div className="News_CT">
      <div className="About_Header_Image">
        <img
          src={require("../Assets/Images/aboutBGtest.png")}
          alt="AboutBG"
          width="100%"
          height="auto"
        />
      </div>

      <div className="News_Paragraph_CT">
        <div className="News_Subject">
          <div className="Copy_2_600_A">Partner</div>
        </div>
        <div className="Partner_Image">
          <img
            src={require("../Assets/Images/aboutBGtest.png")}
            alt="AboutBG"
            width="100%"
            height="auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Partner;
