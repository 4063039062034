import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const About = () => {
  return (
    <div className="About_CT">
      <div className="About_Header_Image">
        <img
          src={require("../Assets/Images/GDPHomeBG2.png")}
          alt="AboutBG"
          width="100%"
          height="auto"
        />
      </div>
      {/* <div className="About_Paragraph_1_CT">
        <div className="About_Subject">
          <div className="Copy_2_600_A">Story</div>
        </div>
        <div className="About_Texts">
          <div className="About_Title">
            <div className="Copy_5_600_A">가치있는 일을 증명하다.</div>
          </div>
          <div className="About_Body">
            <div className="Copy_5_300_B">
              2013년. 블록체인랩스의 창업 멤버들은 미국 샌프란시스코에서
              스타트업을 시작했습니다.블록체인이란 단어조차 생소하던 시절
              블록체인 스타트업은 결코 만만치 않았습니다.기술이 부족했고, 마케팅
              능력도 없었으며, 무엇보다 시장과 소비자를 알지 못했습니다.추진했던
              여러 프로젝트들이 모두 실패로 돌아갔습니다.
            </div>
          </div>
        </div>
      </div> */}
      <div className="About_Paragraph_1_CT">
        <div className="About_Subject">
          <div className="Copy_2_600_A">Value</div>
        </div>
        <div className="About_Texts">
          <div className="About_Title">
            <div className="Copy_5_600_A">가치있는 일을 증명하다.</div>
          </div>
          <div className="About_Body">
            <div className="Copy_5_300_B">
              GDP Studio는 증명서 발급, 관리 서비스 ‘써티파이’를 개발하여 기존
              경력증명서 발급 과정에서 사용자가 느낀 문제점을 해결하는
              기업입니다.경력증명서를 발급하는 기존의 과정은 발급처와 연락을
              주고받아야하는 상황에서 오는 피로감, 유효기간의 존재, 위변조
              가능성 등의 문제가 있습니다.써티파이는 블록체인 기술을 활용한 DID
              기반 경력증명서(VC) 발급 솔루션을 통해 이러한 문제점을 해결합니다.
            </div>
          </div>
        </div>
      </div>
      <div className="About_Paragraph_1_CT">
        <div className="About_Subject">
          <div className="Copy_2_600_A">History</div>
        </div>
        <div className="About_History_CT">
          <div className="About_DotImage">
            <img
              src={require("../Assets/Images/aboutHistoryDot.png")}
              alt="Dot"
              width="100%"
              height="auto"
            />
          </div>
          <div className="About_History_Texts">
            <div className="About_History_Text">
              <div className="About_History_Date">
                <div className="Copy_5_400_A">2021</div>
              </div>
              <div className="About_History_Body">
                <div className="Copy_5_600_A">
                  주식회사 지디피 스튜디오 설립
                </div>
              </div>
            </div>
            <div className="About_History_Text">
              <div className="About_History_Date">
                <div className="Copy_5_400_A">2021</div>
              </div>
              <div className="About_History_Body">
                <div className="Copy_5_600_A">
                  예비창업패키지 한국여성벤처협회 주관 최우수
                </div>
              </div>
            </div>
            <div className="About_History_Text">
              <div className="About_History_Date">
                <div className="Copy_5_400_A">2022</div>
              </div>
              <div className="About_History_Body">
                <div className="Copy_5_600_A">
                  과학기술부, 정보통신산업부(nipa) 주관 K-global 스타트업 선정
                </div>
              </div>
            </div>
            <div className="About_History_Text">
              <div className="About_History_Date">
                <div className="Copy_5_400_A">2023</div>
              </div>
              <div className="About_History_Body">
                <div className="Copy_5_600_A">
                  도전 K-스타트업 청년리그 결선 진출
                </div>
              </div>
            </div>
            <div className="About_History_Text">
              <div className="About_History_Date">
                <div className="Copy_5_400_A">2023</div>
              </div>
              <div className="About_History_Body">
                <div className="Copy_5_600_A">
                  서울 청년창업사관학교 12기 졸업
                </div>
              </div>
            </div>
            <div className="About_History_Text">
              <div className="About_History_Date">
                <div className="Copy_5_400_A">2023</div>
              </div>
              <div className="About_History_Body">
                <div className="Copy_5_600_A">
                  창조경제혁신센터 추천 R&D 연구개발
                </div>
              </div>
            </div>
            <div className="About_History_Text_Last">
              <div className="About_History_Date">
                <div className="Copy_5_400_A">2023</div>
              </div>
              <div className="About_History_Body">
                <div className="Copy_5_600_A">
                  한국인터넷진흥원(KISA) 주관 개인정보보호활용스타트업 선정
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
