import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect, useRef } from "react";

const Home = () => {
  const Home_Partner_Image_Ref = useRef(null);
  const Home_Partner_Image_AniState = useRef(false);

  function Home_Partner_Image_AniPause() {
    if (Home_Partner_Image_Ref.current) {
      Home_Partner_Image_Ref.current.style.animationPlayState = "paused";
    }
  }

  function Home_Partner_Image_AniResume(Home_Partner_Image_AniName) {
    if (Home_Partner_Image_Ref.current) {
      Home_Partner_Image_Ref.current.style.animation = "none";
      void Home_Partner_Image_Ref.current.offsetWidth;
      Home_Partner_Image_Ref.current.style.animation =
        Home_Partner_Image_AniName;
    }
  }

  function Home_Partner_Image_Scroll() {
    const Home_Partner_Image_Scroll_P =
      (window.scrollY / document.documentElement.scrollHeight) * 100;
    let Home_Partner_Image_Scroll_S;
    const deviceWidth = window.innerWidth;

    if (deviceWidth < 768) {
      Home_Partner_Image_Scroll_S = 0;
    } else if (deviceWidth >= 768 && deviceWidth < 1024) {
      Home_Partner_Image_Scroll_S = 0;
    } else {
      Home_Partner_Image_Scroll_S = 0;
    }

    if (Home_Partner_Image_Scroll_P >= Home_Partner_Image_Scroll_S) {
      if (!Home_Partner_Image_AniState.current) {
        Home_Partner_Image_AniState.current = true;
        Home_Partner_Image_AniStart();
      }
    }
  }

  function Home_Partner_Image_AniStart() {
    Home_Partner_Image_AniState.current = true;
    Home_Partner_Image_AniResume(
      "Home_Partner_Image_Anime 20s linear normal forwards"
    );
    window.removeEventListener("scroll", Home_Partner_Image_Scroll);
  }

  useEffect(() => {
    function Home_Partner_Image_AniEnd(event) {
      const Home_Partner_Image_AniName = event.animationName;

      if (Home_Partner_Image_AniName === "Home_Partner_Image_Anime") {
        Home_Partner_Image_AniPause();
        setTimeout(() => {
          Home_Partner_Image_AniResume(
            "Home_Partner_Image_Anime 20s linear normal forwards"
          );
        }, 3000);
      }
    }
    const Home_Partner_Image_Element = Home_Partner_Image_Ref.current;

    if (Home_Partner_Image_Element) {
      Home_Partner_Image_Element.addEventListener(
        "animationend",
        Home_Partner_Image_AniEnd
      );
    }

    return () => {
      if (Home_Partner_Image_Element) {
        Home_Partner_Image_Element.removeEventListener(
          "animationend",
          Home_Partner_Image_AniEnd
        );
      }
    };
  }, []);

  return (
    <div className="Home_CT">
      <div className="Home_Header_Image">
        <img
          src={require("../Assets/Images/GDPHomeBG.png")}
          alt="HomeBG"
          width="100%"
          height="100%"
          style={{ overflow: "hidden" }}
        />
        <div className="Home_Header_Texts">
          <div className="Home_Header_Title">
            <div className="Copy_1_600_A">
              위대한 꿈을 이루는 선구자,
              <br />
              지디피 스튜디오가
              <br />
              나아갈 길입니다.
            </div>
          </div>
          <div className="Home_Header_Body">
            <div className="Copy_5_300_A" style={{ color: "white" }}>
              DID 기반 경력증명서 발급 솔루션을 통해 경력 증명의 새로운 장을
              펼치겠습니다.
            </div>
          </div>
        </div>
      </div>
      <div className="Home_TechSummary_CT"></div>
      <div className="Home_Partner_CT">
        <div className="Home_Partner_BG">
          <div className="Home_Partner_Image" ref={Home_Partner_Image_Ref}>
            <img
              src={require("../Assets/Images/ProblemAll.png")}
              alt="ProblemAll"
              style={{ width: "1000%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
