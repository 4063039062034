import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const Footer = () => {
  return (
    <div className="Footer_CT">
      <div className="Footer_Logo">
        <img
          src={require("../Assets/Logo/GDPStudioLogo.png")}
          alt="Logo"
          width="100%"
          height="auto"
        />
      </div>
      <div className="Footer_Texts">
        <div className="Footer_Text">
          <div className="Copy_7_400_A">대표자 : 이유</div>
        </div>
        <div className="Footer_Text">
          <div className="Copy_7_400_A">사업자 등록번호 : 146-87-02284</div>
        </div>
        <div className="Footer_Text">
          <div className="Copy_7_400_A">
            주소 : 서울특별시 금천구 가산디지털1로 120 서울디지털산업단지
            G밸리창업큐브 603 (08590)
          </div>
        </div>
        <div className="Footer_Text">
          <div className="Copy_7_400_A">이메일 : help@certifie.io</div>
        </div>
        <div className="Footer_Text">
          <div className="Copy_7_400_A">
            ⓒ 2022 GDP Studio Inc. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
