import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const Tech = () => {
  return (
    <div className="About_CT">
      <div className="About_Header_Image">
        <img
          src={require("../Assets/Images/GDPChainBG.png")}
          alt="AboutBG"
          width="100%"
          height="auto"
        />
      </div>
      <div className="About_Paragraph_1_CT">
        <div className="About_Subject">
          <div className="Copy_2_600_A">Tech</div>
        </div>
        <div className="About_Texts">
          <div className="About_Title">
            <div className="Copy_5_600_A">DID 기반 VC 발급</div>
          </div>
          <div className="About_Body">
            <div className="Copy_5_300_B">
              모바일 앱으로 발급 요청을 하면 DID 기반의 VC를 발급하고 백업 할 수
              있어 언제든지 재발급이 가능합니다. 발급된 VC는 블록체인을
              기반으로한 진위여부 판별을 거치게 되며 DID 기반의 신원정보 확인과
              유효검증을 통해 발급 기관 인증을 진행합니다.
            </div>
          </div>
          <div className="Tech_Image">
            <img
              src={require("../Assets/Images/Tech1Image.png")}
              alt="AboutBG"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </div>
      {/* <div className="About_Paragraph_2_CT">
        <div className="About_Subject">
          <div className="Copy_2_600_A">Tech 2</div>
        </div>
        <div className="About_Texts">
          <div className="About_Title">
            <div className="Copy_5_600_A">기술 이름 2</div>
          </div>
          <div className="About_Body">
            <div className="Copy_5_300_B">
              블록체인랩스의 존재 이유는 기술로 보다 많은 사람들이 더 많은
              혜택을 누릴 수 있는 미래를 만들기 위함입니다.이 뜻에 맞지 않는다면
              회사에 막대한 이윤이 생겨도 우리는 그 길을 가지 않을
              것입니다.우리에게 이윤은 목적이 아닌 수단입니다.우리가 올바르고
              아름다운 미래를 그려나간다면 기꺼이 많은 사람들이 공감하고 동참해
              줄 것을 믿으며,이윤은 자연스럽게 우리를 따라올 것입니다. 그리고
              그것은 미래를 위한 수단으로 다시 쓰일 것입니다.이 아름다운 순환을
              반드시 만들어 보겠습니다.
            </div>
          </div>
          <div className="Tech_Image">
            <img
              src={require("../Assets/Images/aboutBGtest.png")}
              alt="AboutBG"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Tech;
