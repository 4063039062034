const ArrowIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 23.95L15 8C14.8333 7.8 14.7416 7.55 14.725 7.25C14.7083 6.95 14.8 6.71667 15 6.55C15.2 6.35 15.45 6.25 15.75 6.25C16.05 6.25 16.3 6.35 16.5 6.55L32.05 22.1C32.35 22.3667 32.55 22.6583 32.65 22.975C32.75 23.2917 32.8 23.6167 32.8 23.95C32.8 24.3167 32.75 24.6583 32.65 24.975C32.55 25.2917 32.35 25.5833 32.05 25.85L16.5 41.4C16.3 41.6 16.05 41.7 15.75 41.7C15.45 41.7 15.2 41.6 15 41.4C14.8 41.2333 14.7 41 14.7 40.7C14.7 40.4 14.8 40.15 15 39.95L31 23.95Z"
        fill="#3769FC"
      />
    </svg>
  );
};

export default ArrowIcon;
