import "./GlobalStyle.css";
import "./PCStyle.css";
import "./TabletStyle.css";
import "./MobileStyle.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import GNB from "./Components/GNB";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Tech from "./Pages/Tech";
import Product from "./Pages/Product";
// import News from "./Pages/News";
import Partner from "./Pages/Partner";
import Contact from "./Pages/Contact";
import Footer from "./Components/Footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <GNB />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/tech" element={<Tech />} />
          <Route path="/product" element={<Product />} />
          {/* <Route path="/news" element={<News />} /> */}
          <Route path="/partner" element={<Partner />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
