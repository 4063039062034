import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Menu from "./Menu";
import MenuOn from "./MenuOn";
import MenuArrowIcon from "./MenuArrowIcon";
import MenuDevider from "./MenuDevider";

const GNB = () => {
  const screenWidth = window.innerWidth;
  const [currentPage, setCurrentPage] = useState("home");
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const location = useLocation();

  const [height, setHeight] = useState(window.innerHeight - 64);

  useEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight - 64);
    };

    // 윈도우의 크기가 변경될 때마다 높이 업데이트
    window.addEventListener("resize", updateHeight);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  React.useEffect(() => {
    // 현재 경로를 기반으로 현재 페이지 추적
    const path = location.pathname;
    if (path === "/about") {
      setCurrentPage("about");
    } else if (path === "/tech") {
      setCurrentPage("tech");
    } else if (path === "/product") {
      setCurrentPage("product");
    } else if (path === "/news") {
      setCurrentPage("news");
    } else if (path === "/partner") {
      setCurrentPage("partner");
    } else if (path === "/team") {
      setCurrentPage("team");
    } else if (path === "/contact") {
      setCurrentPage("contact");
    } else {
      setCurrentPage("home");
    }
  }, [location]);

  const handlerMenubar = () => {
    setIsMenuClicked((prevState) => !prevState);
    console.log(isMenuClicked);
  };

  const style = {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 3,
    // marginTop: "64px",
    width: "100%",
    height: `${height}px`, // 동적으로 높이 값 설정
    backgroundColor: "white",
  };

  return (
    <>
      {screenWidth < 768 ? (
        <>
          {isMenuClicked === true ? (
            <>
              <div className="GNB_BG">
                <Link to="/" className="GNB_Label_Underline">
                  <div className="GNB_Logo_CT" onClick={handlerMenubar}>
                    <img
                      src={require("../Assets/Logo/GDPStudioLogo.png")}
                      alt="Logo"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </Link>

                <button className="GNB_Menu_CT" onClick={handlerMenubar}>
                  <MenuOn />
                </button>
              </div>
              <div className="GNB_MenuBar_BG" style={style}>
                <div className="GNB_MenuBarLabels_CT">
                  <div className="GNB_MenubarLabel_CT">
                    <Link
                      to="/about"
                      className="GNB_MenuBarLabel_Underline"
                      onClick={handlerMenubar}
                    >
                      <div
                        className={`GNB_MenubarLabel_${
                          currentPage === "about" ? "A" : "B"
                        }`}
                      >
                        회사 소개
                      </div>
                      <MenuArrowIcon />
                    </Link>
                    <MenuDevider />
                  </div>
                  <div className="GNB_MenubarLabel_CT">
                    <Link
                      to="/tech"
                      className="GNB_MenuBarLabel_Underline"
                      onClick={handlerMenubar}
                    >
                      <div
                        className={`GNB_MenubarLabel_${
                          currentPage === "tech" ? "A" : "B"
                        }`}
                      >
                        기술
                      </div>
                      <MenuArrowIcon />
                    </Link>
                    <MenuDevider />
                  </div>
                  <div className="GNB_MenubarLabel_CT">
                    <Link
                      to="/product"
                      className="GNB_MenuBarLabel_Underline"
                      onClick={handlerMenubar}
                    >
                      <div
                        className={`GNB_MenubarLabel_${
                          currentPage === "product" ? "A" : "B"
                        }`}
                      >
                        제품
                      </div>
                      <MenuArrowIcon />
                    </Link>
                    <MenuDevider />
                  </div>
                  {/* <div className="GNB_MenubarLabel_CT">
                    <Link
                      to="/news"
                      className="GNB_MenuBarLabel_Underline"
                      onClick={handlerMenubar}
                    >
                      <div
                        className={`GNB_MenubarLabel_${
                          currentPage === "news" ? "A" : "B"
                        }`}
                      >
                        뉴스
                      </div>
                      <MenuArrowIcon />
                    </Link>
                    <MenuDevider />
                  </div> */}
                  {/* <div className="GNB_MenubarLabel_CT">
                    <Link
                      to="/partner"
                      className="GNB_MenuBarLabel_Underline"
                      onClick={handlerMenubar}
                    >
                      <div
                        className={`GNB_MenubarLabel_${
                          currentPage === "partner" ? "A" : "B"
                        }`}
                      >
                        파트너
                      </div>
                      <MenuArrowIcon />
                    </Link>
                    <MenuDevider />
                  </div> */}
                  {/* <a
                    href="https://www.naver.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="GNB_MenubarLabel_CT"
                    onClick={handlerMenubar}
                  >
                    <div className="GNB_MenuBarLabel_Underline">
                      <div className="GNB_MenubarLabel_B">Team</div>
                      <MenuArrowIcon />
                    </div>
                    <MenuDevider />
                  </a> */}
                  <div className="GNB_MenubarLabel_CT_Last">
                    <Link
                      to="/contact"
                      className="GNB_MenuBarLabel_Underline"
                      onClick={handlerMenubar}
                    >
                      <div
                        className={`GNB_MenubarLabel_${
                          currentPage === "contact" ? "A" : "B"
                        }`}
                      >
                        Contact
                      </div>
                      <MenuArrowIcon />
                    </Link>
                    <MenuDevider />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="GNB_BG">
              <Link to="/" className="GNB_Label_Underline">
                <div className="GNB_Logo_CT">
                  <img
                    src={require("../Assets/Logo/GDPStudioLogo.png")}
                    alt="Logo"
                    width="100%"
                    height="100%"
                  />
                </div>
              </Link>

              <button className="GNB_Menu_CT" onClick={handlerMenubar}>
                <Menu />
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="GNB_BG">
          <Link to="/" className="GNB_Label_Underline">
            <div className="GNB_Logo_CT">
              <img
                src={require("../Assets/Logo/GDPStudioLogo.png")}
                alt="Logo"
                width="100%"
                height="auto"
              />
            </div>
          </Link>

          <div className="GNB_Labels_CT">
            <div className="GNB_Label_CT">
              <Link to="/about" className="GNB_Label_Underline">
                <div
                  className={`GNB_Label_Copy_${
                    currentPage === "about" ? "A" : "B"
                  }`}
                >
                  회사 소개
                </div>
              </Link>
            </div>
            <div className="GNB_Label_CT">
              <Link to="/tech" className="GNB_Label_Underline">
                <div
                  className={`GNB_Label_Copy_${
                    currentPage === "tech" ? "A" : "B"
                  }`}
                >
                  기술
                </div>
              </Link>
            </div>
            <div className="GNB_Label_CT">
              <Link to="/product" className="GNB_Label_Underline">
                <div
                  className={`GNB_Label_Copy_${
                    currentPage === "product" ? "A" : "B"
                  }`}
                >
                  제품
                </div>
              </Link>
            </div>
            {/* <div className="GNB_Label_CT">
              <Link to="/news" className="GNB_Label_Underline">
                <div
                  className={`GNB_Label_Copy_${
                    currentPage === "news" ? "A" : "B"
                  }`}
                >
                  뉴스
                </div>
              </Link>
            </div> */}
            {/* <div className="GNB_Label_CT">
              <Link to="/partner" className="GNB_Label_Underline">
                <div
                  className={`GNB_Label_Copy_${
                    currentPage === "partner" ? "A" : "B"
                  }`}
                >
                  파트너
                </div>
              </Link>
            </div> */}
            {/* <a
              href="https://www.naver.com"
              target="_blank"
              rel="noopener noreferrer"
              className="GNB_Label_CT"
            >
              <div className="GNB_Label_Copy_B">Team</div>
            </a> */}
            <div className="GNB_Label_CT_Last">
              <Link to="/contact" className="GNB_Label_Underline">
                <div
                  className={`GNB_Label_Copy_${
                    currentPage === "contact" ? "A" : "B"
                  }`}
                >
                  Contact
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GNB;
